import { SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import DoneAllIcon from 'mdi-react/DoneAllIcon';
import DoneIcon from 'mdi-react/DoneIcon';
import moment from 'moment';
import { any, object } from 'prop-types';
import React from 'react';
import { I18n } from 'utils/i18n';
import isNumeric from 'utils/isNumeric';
import BaseMessage from './BaseMessage';
import Caption from './Caption';
import MessageAvatar from './MessageAvatar';

const styles = () => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '10px',
  },
  right: {
    flexDirection: 'row-reverse',
    alignSelf: 'flex-end',
  },
  loadingIcon: {
    width: 20,
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  seenIcon: {
    marginLeft: 2,
    fontSize: 12,
    opacity: '0.8',
  },
  statusIcon: {
    marginLeft: 2,
    fontSize: 12,
  },
  loaded: {
    fontSize: 12,
    opacity: '0.8',
  },
  time: {
    marginLeft: '0.5em',
  },
  edited: {
    marinRight: '0.5em',
  },
});

const Message = ({ classes, message, children }) => {
  const {
    temp,
    nameNotBlank: name,
    avatar,
    attachments,
    softDeletedAt,
    createdAt,
    isMine,
    error,
  } = message;
  const loaded = message.isMine && !message.temp;
  const seen = message.isMine && message.seen;
  const loading = message.temp;

  const styleRoot = classNames({
    [classes.root]: true,
    [classes.right]: isMine,
  });

  const date = createdAt && isNumeric(createdAt) ? moment.unix(createdAt) : moment(createdAt);
  return (
    <div className={styleRoot}>
      {!message.article && <MessageAvatar right={isMine} name={name} avatar={avatar} />}
      <BaseMessage
        right={isMine}
        attachments={!isEmpty(attachments)}
        isMandatory={
          !message.softDeletedAt && (message?.isMandatory || message?.form || message?.article)
        }
      >
        {children}
      </BaseMessage>
      {!message.article && (
        <Caption right={isMine} className={classes.status}>
          {message?.isEdited && !softDeletedAt ? (
            <span className={classes.edited}>{I18n.t('Message.edited')}</span>
          ) : (
            ''
          )}
          {!loaded && !seen && !error && loading && (
            <SvgIcon className={classes.loaded}>
              <ClockOutlineIcon />
            </SvgIcon>
          )}
          {loaded && !error && !seen && !softDeletedAt && (
            <SvgIcon className={classes.loaded}>
              <DoneIcon />
            </SvgIcon>
          )}
          {seen && !softDeletedAt && (
            <SvgIcon className={classes.seenIcon}>
              <DoneAllIcon />
            </SvgIcon>
          )}
          {error && (
            <SvgIcon color="error" className={classes.statusIcon}>
              <AlertCircleIcon />
            </SvgIcon>
          )}
          {!temp && <span className={classes.time}>{date.format('LT')}</span>}
        </Caption>
      )}
    </div>
  );
};

Message.propTypes = {
  classes: object.isRequired,
  message: object.isRequired,
  children: any.isRequired,
};

export default withStyles(styles)(Message);
