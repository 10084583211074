import makeStyles from '@mui/styles/makeStyles';
import { HrBotContext } from 'components/@home/drawers/HrBotDrawer/hrBotContext';
import DropzoneBox from 'components/common/DropzoneBox';
import Loading from 'components/common/Loading';
import isEmpty from 'lodash/isEmpty';
import { bool, func, number, object } from 'prop-types';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import authUser from 'store/selectors/authUser';
import getPdfjs from 'utils/pdfjs';
import { useDebounceCallback } from 'utils/useDebounceCallback';
import useEffectEvent from 'utils/useEffectEvent';
import DroppedFiles from './DroppedFiles';
import { getFileName, getParts } from './pdf-utils';

const useStyles = makeStyles(th => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: th.spacing(2),
    marginRight: th.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: th.spacing(1),
  },
  submit: {
    margin: th.spacing(3, 0, 2),
    height: 40,
    maxHeight: 40,
  },
  selectWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxHeight: '100%',
    overflow: 'hidden',
  },
  suggestWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  suggest: {
    minWidth: 200,
    marginLeft: th.spacing(3),
  },
  right: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  newTemplateBtn: {
    marginLeft: th.spacing(1),
    padding: 10,
  },
  dialog: {
    width: 600,
  },
  input: {},
  loading: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.readAsArrayBuffer(file);
  });
};

const readFileAsString = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.readAsText(file, 'utf8');
  });
};

const StepFiles = ({
  nextEnabled,
  parsingPct,
  resets,
  setError,
  setNextEnabled,
  setParsingPct,
  userItems,
}) => {
  const classes = useStyles();
  const user = useSelector(authUser);
  const { files, format, setFiles, tab, checkIds, droppedFiles, setDroppedFiles } =
    useContext(HrBotContext);
  const [loading, setLoading] = useState(false);

  const parseFiles = useEffectEvent((newFiles = Object.values(droppedFiles || {})) => {
    if (loading || !newFiles?.length) return;
    setLoading(true);
    const initResets = resets.current;
    if (isEmpty(newFiles)) return;
    const parsed = newFiles.map(() => 0);
    let hasToReset = true;
    setError(null);
    const field = format.sendBy || 'code';
    Promise.all(
      newFiles
        .filter(f => format.type !== 'xml' || f?.name.toLowerCase().endsWith('.xml'))
        .map(async (file, idx) => {
          if (resets.current !== initResets) {
            setParsingPct(0);
            return [];
          }
          let xml;
          let pdf;
          let pdfLoadingTask = null;
          if (format.type === 'xml') {
            xml = await readFileAsString(file);
          } else {
            const arrayBuffer = await readFile(file);
            const fileContent = new Uint8Array(arrayBuffer);
            const pdfjs = await getPdfjs();
            pdfLoadingTask = pdfjs.getDocument(fileContent);
            pdf = await pdfLoadingTask.promise;
          }
          const ids = new Set();
          const fileMap = { xml, 'single-pdf': pdf, 'multi-pdf': pdf };
          await getParts(file.name, fileMap[format.type], format, async (parts, pageIdx) => {
            if (parts[field]) {
              ids.add(parts[field]);
            }
            const employee = {};
            const error =
              Object.entries(parts)
                .filter(([key]) => key !== 'code' || field !== 'id')
                .some(([, value]) => value === '') || !parts[field];
            if (resets.current !== initResets) {
              setParsingPct(0);
              return;
            }
            await setFiles(oldValue => [
              ...(hasToReset ? [] : oldValue || []),
              {
                ...parts,
                file: newFiles[parts.filename] || file,
                xml,
                pageNum: pageIdx,
                newFileName: getFileName({ ...parts, ...userItems }, format),
                error,
                employee,
              },
            ]);
            hasToReset = false;
            const total = format?.type === 'single-pdf' ? pdf.numPages : 1;
            parsed[idx] = Math.max(parsed[idx], ((pageIdx + 1) / total) * 100);
            setParsingPct(Math.round(parsed.reduce((acc, curr) => acc + curr, 0) / parsed.length));
          });
          pdfLoadingTask?.destroy();
          pdfLoadingTask?._worker.destroy();
          return ids;
        }),
    )
      .then(idSets => {
        const ids = Array.from(idSets.reduce((acc, curr) => new Set([...acc, ...curr]), new Set()));
        if (ids?.length > 0) {
          return checkIds({ ids, format: field });
        }
        return { value: { existingEmployees: [] } };
      })
      .then(({ value: { existingEmployees } }) => {
        if (Object.keys(existingEmployees).length > 0) {
          setFiles(oldValue => {
            return oldValue.map(f => {
              const existingEmployee = existingEmployees[f[field]];
              return {
                ...f,
                employee: { ...f.employee, [field]: f[field], ...existingEmployee },
              };
            });
          });
        }
        setLoading(false);
      });
  });

  const handleDrop = useCallback(
    newFiles => {
      setDroppedFiles(newFiles.reduce((acc, f) => ({ ...acc, [f.name]: f }), {}));
      parseFiles(newFiles);
    },
    [setDroppedFiles],
  );

  useEffect(() => {
    if (format) {
      parseFiles();
    }
  }, [format]);

  useEffect(() => {
    if (nextEnabled && parsingPct < 100) {
      setNextEnabled(false);
    } else if (!nextEnabled && parsingPct >= 100) {
      setNextEnabled(true);
    }
  }, [nextEnabled, parsingPct, setNextEnabled]);

  const setErrors = useCallback(() => {
    const hasErrors = files?.filter(f => f.error).length > 0;
    const hasRecipients = files?.filter(f => f.employee?.[format.sendBy]).length > 0;
    if (hasErrors) {
      setError(I18n.t('HrBot.Error parsing some files'));
    } else if (files?.length && !hasRecipients) {
      setError(I18n.t('HrBot.No recipients found'));
    } else {
      setError(null);
    }
    setNextEnabled(!hasErrors && hasRecipients);
  }, [files, format.sendBy, setError, setNextEnabled]);
  const setErrorsDebounced = useDebounceCallback(setErrors, 300);
  useEffect(setErrorsDebounced, [setErrorsDebounced, files, format, setError, setNextEnabled]);

  const accept = {
    pdf: 'application/pdf',
    xml: 'application/pdf, application/xml, text/xml',
  };
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {loading && <Loading className={classes.loading} debounce={20} size={64} />}
        {!loading &&
          format &&
          (files ? (
            <DroppedFiles tab={tab} files={files} isSuperAdmin={user?.isSuperAdmin} />
          ) : (
            <DropzoneBox
              onDrop={handleDrop}
              accept={accept[format.type] || 'application/pdf'}
              multiple
            />
          ))}
      </div>
    </div>
  );
};

StepFiles.propTypes = {
  nextEnabled: bool.isRequired,
  parsingPct: number.isRequired,
  resets: object.isRequired,
  setError: func.isRequired,
  setNextEnabled: func.isRequired,
  setParsingPct: func.isRequired,
  userItems: object.isRequired,
};

export default memo(StepFiles);
