/* eslint-disable prefer-destructuring,function-paren-newline */
import getLinkPreview from 'store/selectors/getLinkPreview';
import Message from 'services/api/Message';
import Preview from 'services/api/Preview';
import currentChannel from 'store/selectors/currentChannel';

export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const LOAD_MESSAGES_PENDING = 'LOAD_MESSAGES_PENDING';
export const LOAD_MESSAGES_FULFILLED = 'LOAD_MESSAGES_FULFILLED';
export const LOAD_MESSAGES_REJECTED = 'LOAD_MESSAGES_REJECTED';

export const READ_MESSAGES = 'READ_MESSAGES';
export const READ_MESSAGES_FULFILLED = 'READ_MESSAGES_FULFILLED';

export const GENERATE_MESSAGE_PREVIEW = 'GENERATE_MESSAGE_PREVIEW';
export const GENERATE_MESSAGE_PREVIEW_PENDING = 'GENERATE_MESSAGE_PREVIEW_PENDING';
export const GENERATE_MESSAGE_PREVIEW_FULFILLED = 'GENERATE_MESSAGE_PREVIEW_FULFILLED';
export const GENERATE_MESSAGE_PREVIEW_REJECTED = 'GENERATE_MESSAGE_PREVIEW_REJECTED';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_PENDING = 'SEND_MESSAGE_PENDING';
export const SEND_MESSAGE_FULFILLED = 'SEND_MESSAGE_FULFILLED';
export const SEND_MESSAGE_REJECTED = 'SEND_MESSAGE_REJECTED';
export const ADD_MESSAGE = 'ADD_MESSAGE';

export const CONFIRM_ACKNOWLEDGE_MESSAGE = 'CONFIRM_ACKNOWLEDGE_MESSAGE';
export const CONFIRM_ACKNOWLEDGE_MESSAGE_PENDING = 'CONFIRM_ACKNOWLEDGE_MESSAGE_PENDING';
export const CONFIRM_ACKNOWLEDGE_MESSAGE_FULFILLED = 'CONFIRM_ACKNOWLEDGE_MESSAGE_FULFILLED';
export const CONFIRM_ACKNOWLEDGE_MESSAGE_REJECTED = 'CONFIRM_ACKNOWLEDGE_MESSAGE_REJECTED';

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_MESSAGE_FULFILLED = 'UPDATE_MESSAGE_FULFILLED';
export const UPDATE_MESSAGE_REJECTED = 'UPDATE_MESSAGE_REJECTED';

export const DESTROY_MESSAGE = 'DESTROY_MESSAGE';
export const DESTROY_MESSAGE_PENDING = 'DESTROY_MESSAGE_PENDING';
export const DESTROY_MESSAGE_FULFILLED = 'DESTROY_MESSAGE_FULFILLED';
export const DESTROY_MESSAGE_REJECTED = 'DESTROY_MESSAGE_REJECTED';

export const ON_READ_MESSAGE = 'ON_READ_MESSAGE';

export const DIGITAL_SIGNATURE_MESSAGE = 'DIGITAL_SIGNATURE_MESSAGE';
export const DIGITAL_SIGNATURE_MESSAGE_PENDING = 'DIGITAL_SIGNATURE_MESSAGE_PENDING';
export const DIGITAL_SIGNATURE_MESSAGE_FULFILLED = 'DIGITAL_SIGNATURE_MESSAGE_FULFILLED';
export const DIGITAL_SIGNATURE_MESSAGE_REJECTED = 'DIGITAL_SIGNATURE_MESSAGE_REJECTED';

export const SEARCH_MESSAGES = 'SEARCH_MESSAGES';
export const SEARCH_MESSAGES_PENDING = 'SEARCH_MESSAGES_PENDING';
export const SEARCH_MESSAGES_FULFILLED = 'SEARCH_MESSAGES_FULFILLED';
export const SEARCH_MESSAGES_REJECTED = 'SEARCH_MESSAGES_REJECTED';

export const MARK_AS_READ_MESSAGES = 'MARK_AS_READ_MESSAGES';

export const PERMISSIONS_MESSAGE = 'PERMISSIONS_MESSAGE';
export const PERMISSIONS_MESSAGE_PENDING = 'PERMISSIONS_MESSAGE_PENDING';
export const PERMISSIONS_MESSAGE_FULFILLED = 'PERMISSIONS_MESSAGE_FULFILLED';
export const PERMISSIONS_MESSAGE_REJECTED = 'PERMISSIONS_MESSAGE_REJECTED';

export const PIN_MESSAGE = 'PIN_MESSAGE';
export const PIN_MESSAGE_PENDING = 'PIN_MESSAGE_PENDING';
export const PIN_MESSAGE_FULFILLED = 'PIN_MESSAGE_FULFILLED';
export const PIN_MESSAGE_REJECTED = 'PIN_MESSAGE_REJECTED';

export const UNPIN_MESSAGE = 'UNPIN_MESSAGE';
export const UNPIN_MESSAGE_PENDING = 'UNPIN_MESSAGE_PENDING';
export const UNPIN_MESSAGE_FULFILLED = 'UNPIN_MESSAGE_FULFILLED';
export const UNPIN_MESSAGE_REJECTED = 'UNPIN_MESSAGE_REJECTED';

const generatePreview = (url, messageId) => (dispatch, getState) =>
  dispatch({
    type: GENERATE_MESSAGE_PREVIEW,
    payload: getLinkPreview(getState(), url)
      ? Promise.resolve(getLinkPreview(getState(), url))
      : Preview.generate(url),
    meta: { link: url, message_id: messageId },
  });

const load =
  (channelId, { replace, ...options } = {}) =>
  (dispatch, getState) =>
    dispatch({
      type: LOAD_MESSAGES,
      payload: Message.list(channelId, options),
      meta: {
        channel_id: channelId,
        allMessages: currentChannel(getState())?.messages || [],
        currentId: getState().elements.ChannelsPanel.currentId,
        complete: ['beforeId', 'aroundId'].every(key => !options[key]),
        replace,
        ...options,
      },
    });

const search = s => ({
  type: SEARCH_MESSAGES,
  payload: Message.search(s),
});

const read = channelId => (dispatch, getState) =>
  dispatch({
    type: READ_MESSAGES,
    payload: Message.read(channelId, []),
    meta: { channel_id: channelId, authUser_id: getState().auth.user_id },
  });

const send = message => (dispatch, getState) => {
  const { elements, auth } = getState();
  const { watchedChannel_id: watchedChannelId } = elements.ChatPanel;

  dispatch({
    type: SEND_MESSAGE,
    payload: Message.send(message),
    meta: {
      message_id: message._id,
      message,
      watchedChannel_id: watchedChannelId,
      authUserId: auth.user_id,
    },
  });
};

const add = message => (dispatch, getState) =>
  dispatch({
    type: ADD_MESSAGE,
    payload: message,
    meta: {
      currentId: getState().elements.ChannelsPanel.currentId,
      authUserId: getState().auth.user_id,
      watchedChannel_id: getState().elements.ChatPanel.watchedChannel_id,
      // Relevant to set the number of unread messages:
      // If the page is hidden (tab is not active) the message should be considered
      // unread even if the channel is the current on
      hasFocus: getState().elements.Focus.hasFocus,
    },
  });

const addAndMaybeRead = message => async (dispatch, getState) => {
  await dispatch(add(message));
  const currentChannelId = currentChannel(getState())?._id;

  // Mark as read if we add a new message in the current open channel and the documnent has focus
  if (message.channel === currentChannelId && getState().elements.Focus.hasFocus) {
    dispatch(read(currentChannelId));
  }
};

const confirmAcknowledge = messageId => (dispatch, getState) =>
  dispatch({
    type: CONFIRM_ACKNOWLEDGE_MESSAGE,
    payload: Message.confirm(messageId),
    meta: {
      channel_id: getState().elements.ChannelsPanel.currentId,
      message_id: messageId,
      authUser_id: getState().auth.user_id,
    },
  });

const update = message => ({
  type: UPDATE_MESSAGE,
  payload: Message.update(message),
  meta: { message_id: message._id, message },
});

const updateSuccess = message => ({
  type: UPDATE_MESSAGE_FULFILLED,
  meta: { message },
});

const markAsRead = channelId => (dispatch, getState) =>
  dispatch({
    type: MARK_AS_READ_MESSAGES,
    payload: channelId,
    meta: { channel_id: channelId, authUser_id: getState().auth.user_id },
  });

const loadAndRead =
  (channelId, ...params) =>
  async dispatch => {
    await dispatch(load(channelId, ...params));

    dispatch(read(channelId));
  };

const destroy = messageId => (dispatch, getState) => {
  const { messages, _id } = currentChannel(getState());
  const lastMessageId = messages[messages.length - 1]?._id;

  return dispatch({
    type: DESTROY_MESSAGE,
    payload: Message.delete(messageId),
    meta: {
      message_id: messageId,
      lastMessage_id: lastMessageId,
      channel_id: _id,
    },
  });
};

const destroyFulfilled = message => (dispatch, getState) => {
  const { messages, _id } = currentChannel(getState());
  const lastMessageId = messages[messages.length - 1]?._id;

  return dispatch({
    type: DESTROY_MESSAGE_FULFILLED,
    payload: {
      message,
    },
    meta: {
      message_id: message._id,
      lastMessage_id: lastMessageId,
      channel_id: _id,
    },
  });
};

const onReadMessage = payload => ({
  type: ON_READ_MESSAGE,
  payload,
});

const addDigitalSignature = messageId => ({
  type: DIGITAL_SIGNATURE_MESSAGE,
  payload: Message.digitalSignature(messageId),
});

const permissions = messageId => ({
  type: PERMISSIONS_MESSAGE,
  payload: Message.permissions(messageId),
  meta: {
    message_id: messageId,
  },
});

const pin = message => (dispatch, getState) => {
  const { _id: channelId, pinnedMessage } = currentChannel(getState());
  return dispatch({
    type: PIN_MESSAGE,
    payload: Message.pin(message._id),
    meta: { message, channelId, pinnedMessage },
  });
};

const unpin = message => (dispatch, getState) => {
  const { _id: channelId, pinnedMessage } = currentChannel(getState());
  return dispatch({
    type: UNPIN_MESSAGE,
    payload: Message.unpin(message._id),
    meta: { message, channelId, pinnedMessage },
  });
};

export default {
  add,
  addAndMaybeRead,
  addDigitalSignature,
  confirmAcknowledge,
  destroy,
  destroyFulfilled,
  generatePreview,
  load,
  loadAndRead,
  markAsRead,
  onReadMessage,
  permissions,
  pin,
  read,
  search,
  send,
  unpin,
  update,
  updateSuccess,
};
